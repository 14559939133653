import React from "react";
import { Box } from '@mui/system';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import * as ReactDOM from 'react-dom';
import ControlledSelectionGrid from './ProductGridFuctions';
import LinearProgress from '@mui/material/LinearProgress';
import configData from "../config/config.json";
import axios from 'axios'
import SyncIcon from '@mui/icons-material/Sync';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack'; 
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

export default function ProductsGrid(props) {

  const orderManage = (status) => {

    var order_id = props.order_id;
    var entity_id = props.entity_id;

    var todayDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');

    if(status === 1){

      const dataComment = { "entity_id": entity_id, "statusHistory": {"comment": "Produsul a fost comandat", "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 1,"is_visible_on_front": 1,"parent_id": entity_id}};
      
      const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

      axios.all([postComment])
      .then(ReactDOM.render(<LinearProgress />, document.getElementById(order_id)))
      .then(axios.spread((...responses) => {
      console.log("RESPONSE RECEIVED: ", responses[0]);
      ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
      Comment added and status changed: {responses[0].status}
      </Alert>, document.getElementById(order_id));
      }))
      .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
          <AlertTitle>Error</AlertTitle>
      {err.code} - {err.message}
      </Alert>, document.getElementById(order_id));
      })
    }
    else if(status === 2){
      const dataComment = { "entity_id": entity_id, "statusHistory": {"comment": "Comanda a fost îndeplinită", "status": "complete", "entity_id": 0,"entity_name": "order","is_customer_notified": 1,"is_visible_on_front": 1,"parent_id": entity_id}};
      
      const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

      axios.all([postComment])
      .then(ReactDOM.render(<LinearProgress />, document.getElementById(order_id)))
      .then(axios.spread((...responses) => {
      console.log("RESPONSE RECEIVED: ", responses[0]);
      ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
      Comment added and status changed: {responses[0].status}
      </Alert>, document.getElementById(order_id));
      }))
      .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
          <AlertTitle>Error</AlertTitle>
      {err.code} - {err.message}
      </Alert>, document.getElementById(order_id));
      })
    }
    else if(status === 0){
      const dataComment = { "entity_id": entity_id, "statusHistory": {"comment": "Comanda nu poate fi îndeplinită", "status": "canceled", "entity_id": 0,"entity_name": "order","is_customer_notified": 1,"is_visible_on_front": 1,"parent_id": entity_id}};
      
      const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

      axios.all([postComment])
      .then(ReactDOM.render(<LinearProgress />, document.getElementById(order_id)))
      .then(axios.spread((...responses) => {
      console.log("RESPONSE RECEIVED: ", responses[0]);
      ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
      Comment added and status changed: {responses[0].status}
      </Alert>, document.getElementById(order_id));
      }))
      .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
          <AlertTitle>Error</AlertTitle>
      {err.code} - {err.message}
      </Alert>, document.getElementById(order_id));
      })
    }
}
  
  function trimBrand(product) {
    var brands = ['VAG_', 'DACIA/RENAULT_', 'ALFA-ROMEO_','AUDI_','BENTLEY_','BMW_','CHEVROLET_','CHRYSLER_','CITROEN_','DODGE_','DACIA_','DAEWOO_','DAIHATSU_','FIAT_','FORD_','GENERAL_MOTORS_','GMC_','HONDA_','HYUNDAI_','INFINITI_','ISUZU_','JAGUAR_','JEEP_','KIA_','LAMBORGHINI_','LANCIA_','LEXUS_','LAND_ROVER_','MAYBACH_','MAZDA_','MERCEDES-BENZ_','MB_A','MINI_','MITSUBISHI_','NISSAN_','OPEL_','PEUGEOT_','PORSCHE_','RENAULT_','ROLLS-ROYCE_','ROVER_','SAAB_','SEAT_','SUBARU_','SUZUKI_','SMART_','TOYOTA_','VAUXHALL_','VOLKSWAGEN_','VOLVO_','YAMAHA_']
    var clearNumber = product;
  
    for(var i = 0; i < brands.length; i++){
        clearNumber = clearNumber.replace(brands[i],'')
    }
  
    return clearNumber.replace(/_/g,'');
  }

  
  function Columns(order) {
  
    var order_id = order;
  
    /* console.log(order_id) */
  
    const columns = [
      { field: 'name', headerName: configData.STRINGS.PRODUCTS_GRID.COLUMNS.PRODUCT_NAME, width: 250 },
      {
      field: "action",
      headerName: configData.STRINGS.PRODUCTS_GRID.COLUMNS.PRODUCT_AVAILABILITY,
      width: 250,
      editable: true,
      sortable: false,
      renderCell: (params) => {
  
        const onClickCheckAvailability = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};
          //const rowId: Record<int, GridRowId> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
  
  
          const darucarApi = axios.get(configData.EXTERNAL_APIS.EUROEST_PRODUCT+trimBrand(thisRow.name), {timeout: 10000 })
  
            axios.all([darucarApi])
            .then(ReactDOM.render(<LinearProgress />, document.getElementById(order_id)))
            .then(axios.spread((...responses) => {
            //console.log("RESPONSE RECEIVED: ", responses[0]);
            //console.log("RESPONSE RECEIVED: ", responses[1]);
            //console.log("RESPONSE RECEIVED: ", responses[2]);
  
            var messagesApis = [];
               
            if(responses[0].data.length > 0)
                {
                //var messageEuroest = '';
                for(var t = 0; t < responses[0].data.length; t++) {
                  messagesApis.push('<b>Euroest</b>: Cod: '+responses[0].data[t].manufacturer+' '+responses[0].data[t].code+' | Pret (EUR) '+responses[0].data[t].price+' EUR | Livrare: '+responses[0].data[t].delivery+' | Stoc: '+responses[0].data[t].stock);
                }
                } else {
                messagesApis.push('<b>Euroest</b>: '+configData.STRINGS.MESSAGE.NO_RESULTS_EUROEST+trimBrand(thisRow.name));
              }

              let textMessages = messagesApis.join(" <br> ");
              
              ReactDOM.render(
                <Alert severity="success" sx={{ width: '98%' }}>
                <div dangerouslySetInnerHTML={{__html: textMessages}} />
                </Alert>, document.getElementById(order_id));
                
                }))
            .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
                <AlertTitle>Error</AlertTitle>
            {err.code} - {err.message}<br></br>
            </Alert>, document.getElementById(order_id));
            })
        };
  
        
          return <React.Fragment><Button onClick={onClickCheckAvailability} startIcon={<SyncIcon/>}>Check price/stock</Button></React.Fragment>;
        
      }
      },
      { field: 'qty_ordered', headerName: configData.STRINGS.PRODUCTS_GRID.COLUMNS.PRODUCT_QTY, width: 130 },
      { field: 'price_incl_tax', headerName: configData.STRINGS.PRODUCTS_GRID.COLUMNS.PRODUCT_PRICE, width: 130 },
      { field: 'row_total_incl_tax', headerName: configData.STRINGS.PRODUCTS_GRID.COLUMNS.PRODUCT_TOTAL_PRICE, width: 130 }];
  
      return columns;
  }
    
    var row = props.rows;
    var order_date = props.order_date;
    var order_id = props.order_id;
    var entity_id = props.entity_id;
    var kh_code = props.customer;
    var branch = props.branch;

    /* console.log(row) */

    return (
      <TableRow key={row.product_id}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={true} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
            Managementul comenzilor
            </Typography>
            <div style={{ width: '100%' }}>
            <Stack direction="row" spacing={1} paddingBottom={2}>
              <Tooltip title="Va fi postat un comentariu precum „Articolele au fost comandate”.">
                  <Button size="small" variant="outlined" onClick={() => orderManage(1)} endIcon={<CheckIcon />}>Confirmați comanda</Button>
              </Tooltip>
              <Tooltip title="Aceasta va schimba starea comenzii la Finalizat și va adăuga un comentariu.">
                  <Button size="small" variant="outlined" onClick={() => orderManage(2)} endIcon={<CheckCircleIcon />}>Comanda completa</Button>
              </Tooltip>
              <Tooltip title="Aceasta va închide comanda și va adăuga un comentariu.">
                  <Button size="small" variant="outlined" onClick={() => orderManage(0)} endIcon={<CancelIcon />}>Anulează comanda</Button>
              </Tooltip>
            </Stack>
            </div>
          </Box>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              {configData.STRINGS.PRODUCTS_GRID.HEADERS.PRODUCTS}
            </Typography>
            <div style={{ height: 'auto', width: '100%' }}>
              <ControlledSelectionGrid data={row} order_id={order_id} columns={Columns(order_id)} entity_id={entity_id} branch={branch}  customer={kh_code} order_date={order_date}/>
            </div>
          </Box>
        </Collapse>
      </TableCell>
      </TableRow>
    );
  }