import React, {Component } from "react";
import { Box, Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import OrdersTable from './components/OrdersTable';
import Login from './components/Login';
import ProductsGrid from './components/ProductsGrid';
import LinearProgress from '@mui/material/LinearProgress';
import configData from "./config/config.json";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: true,
      setOpen: false,
      items: [],
      date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
      statusOrders: 'all',
      isLoaded: false,
      vertical: 'top',
      horizontal: 'center',
      logged_as: '',
      username: '',
      password: '',
      isLogged: false  }
  }

  componentDidMount () {
   
    let getToken = sessionStorage.getItem('tokenDashboard');

    if(getToken && getToken.length > 0) {
      this.setState({
        isLogged: true
      })

    fetch (configData.MAGENTO_APIS.MAGENTO_ORDERS+this.state.date+'/'+this.state.statusOrders,
      {
      method: "GET"
      })  
      .then(res => res.json())
      .then(json => {
          this.setState({
            isLoaded: true,
            items: json.items,
          })
      })
    }
  }


  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props1):
    if (this.state.date !== prevState.date || this.state.statusOrders !== prevState.statusOrders) {

      fetch (configData.MAGENTO_APIS.MAGENTO_ORDERS+this.state.date+'/'+this.state.statusOrders,
        {
        method: "GET"
        })  
        .then(res => res.json())
        .then(json => {
            this.setState({
              isLoaded: true,
              items: json.items,
            })
        })
    }
  }

  handleChangeStatus(event){
   
    // Changing state
    this.setState({statusOrders : event.target.value})
    this.setState({isLoaded : false})
  }



  render() {

    var {isLoaded,items,date,statusOrders,isLogged} = this.state;

    if(!isLogged) {
      return (
        <div style={{display: 'flex', bottom: 0, justifyContent: 'center'}}>
        <Login />
        </div>)
    }

    else {

    if(!isLoaded) {
      return (
        <div style={{display: 'flex', bottom: 0, justifyContent: 'center'}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
        </div>
)
    }

    else {

      const statusOption = [
        {
          value: 'all',
          label: 'Toate',
        },
        {
          value: 'processing',
          label: 'Prelucrare',
        },
        {
          value: 'complete',
          label: 'Complet',
        },
        {
          value: 'canceled',
          label: 'Anulat',
        },
      ];

    return (
      <React.Fragment>
        <Container maxWidth="xl" style={{ marginTop: 20, marginBottom: 20 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    views={["year", "month", "day"]}
                    label="Start date"
                    value={date}
                    inputFormat="yyyy-MM-dd"
                    onChange={(newValue) => {
                      this.setState({
                        isLoaded: false,
                        date: new Date(newValue).toJSON().slice(0,10).replace(/-/g,'-')
                      })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <TextField
                id="outlined-select-currency"
                select
                label="Select"
                value={statusOrders}
                onChange={this.handleChangeStatus.bind(this)}
                style={{ mt: 1 }}
              >
                {statusOption.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
        </Container>
      <Container maxWidth="xl">
      <TableContainer component={Paper} width="600px">
      <Table aria-label="collapsible table" style={{ marginTop: 20 }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">{configData.STRINGS.ORDERS.ORDER_ID}</TableCell>
            <TableCell align="center">{configData.STRINGS.ORDERS.ORDER_DATE}</TableCell>
            <TableCell align="center">{configData.STRINGS.ORDERS.ORDER_STATUS}</TableCell>
            <TableCell align="center">{configData.STRINGS.ORDERS.ORDER_CUSTOMER}</TableCell>
            <TableCell align="center">{configData.STRINGS.ORDERS.ORDER_VALUE}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
                  <React.Fragment key={row.increment_id}>
                    <OrdersTable key={row.increment_id} rows={row} customer={row.customer_id}/>
                    <ProductsGrid key={row.items.product_id} rows={row.items} order_id={row.increment_id} order_date={row.created_at} entity_id={row.entity_id} branch={row.extension_attributes.branch} customer={row.extension_attributes.kh_code}/>
                  </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    </React.Fragment>
    );

  }
}
  }
}

export default App;