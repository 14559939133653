import React, {useState} from 'react';
import axios from 'axios';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactDOM from 'react-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack'; 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import configData from "../config/config.json";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ControlledSelectionGrid(props) {

  const statusOption = [
    {
      value: 'processing',
      label: 'Prelucrare',
    },
    {
      value: 'complete',
      label: 'Complet',
    },
    {
      value: 'canceled',
      label: 'Anulat',
    },
  ];

  const [currentStatus, setCurrentStatus] = React.useState('processing');

    const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentStatus(event.target.value);
    };

    const [valueComment, setValue] = React.useState('');

    const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

    const [isLoading, setLoading] = useState(true);
    const [comments, setComments] = useState({});
    const [messages, setMessages] = useState({});

    const publishComment = () => {

      setLoading(true);

      var entity_id = props.entity_id;
      var order_id = props.order_id;

        if(valueComment.length === 0){
          ReactDOM.render(<Alert severity="warning" sx={{ width: '98%' }}>
            <AlertTitle>Avertizare</AlertTitle>
            Trebuie să adăugați ceva text!
        </Alert>, document.getElementById(order_id));
      }
      else {
        var todayDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');

        const dataComment = { "entity_id": entity_id, "statusHistory": {"comment": valueComment, "status": currentStatus, "entity_id": 0,"entity_name": "order","is_customer_notified": 1,"is_visible_on_front": 1,"parent_id": entity_id}};

        const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

        axios.all([postComment])
        .then(ReactDOM.render(<LinearProgress />, document.getElementById(order_id)))
        .then(axios.spread((...responses) => {
        console.log("RESPONSE RECEIVED: ", responses[0]);
        ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        Comment posted!
        </Alert>, document.getElementById(order_id));
        reqData();
        }))
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById(order_id));
        })
      }
    };

    const [selectionModel, setSelectionModel] = React.useState([]);

    function trimBrand(product) {
      var brands = ['VAG_', 'ALFA-ROMEO_','AUDI_','BENTLEY_','BMW_','CHEVROLET_','CHRYSLER_','CITROEN_','DODGE_','DACIA_','DAEWOO_','DAIHATSU_','FIAT_','FORD_','GENERAL_MOTORS_','GMC_','HONDA_','HYUNDAI_','INFINITI_','ISUZU_','JAGUAR_','JEEP_','KIA_','LAMBORGHINI_','LANCIA_','LEXUS_','LAND_ROVER_','MAYBACH_','MAZDA_','MERCEDES-BENZ_','MB_A','MINI_','MITSUBISHI_','NISSAN_','OPEL_','PEUGEOT_','PORSCHE_','RENAULT_','ROLLS-ROYCE_','ROVER_','SAAB_','SEAT_','SUBARU_','SUZUKI_','SMART_','TOYOTA_','VAUXHALL_','VOLKSWAGEN_','VOLVO_','YAMAHA_']
      var clearNumber = product;
    
      for(var i = 0; i < brands.length; i++){
          clearNumber = clearNumber.replace(brands[i],'')
      }
    
      return clearNumber.replace(/_/g,'');
    }

    //Copytoclipboard function
    const copyToClipboar = () => {
        var text = ''
        //take order_id number passed from ProductsGrid component
        var order_id = props.order_id;
        var kh_code = props.customer;
        var branch = props.branch;

        var order_date = props.order_date;
        var clear_date = new Date(order_date).toJSON().slice(0,10).replace(/-/g,'/');

        for(var i = 0; i < selectionModel.length; i++) {
            const before_ = selectionModel[i].name.substring(0, selectionModel[i].name.indexOf('_'));
            text += ';'+clear_date+';'+before_+';'+order_id+';'+branch+';'+kh_code+';'+selectionModel[i].name+';'+selectionModel[i].qty_ordered+';;;;;;;'+selectionModel[i].price_incl_tax+'\n'
        }

        return text;
    }


    //function to generate custom toolbar in the grid with Export and Copy to clipboard options
    function CustomToolbar() {
        return (
              <GridToolbarContainer>
                  <Stack direction="row" spacing={1}>
                    <GridToolbarExport />
                    <Button size="small" onClick={() =>  navigator.clipboard.writeText(copyToClipboar())} startIcon={<ContentCopyIcon />}>Copy to clipboard</Button>
                </Stack>
              </GridToolbarContainer>
            );
          }

    //take products data passed from ProductsGrid component
    const data = props.data;
    //take columns data passed from ProductsGrid component
    const columns = props.columns;

    //take order_id number passed from ProductsGrid component
    var order_id = props.order_id;

    // Function for sending orders via email - Darucar, Autobavaria, SilverStar
    const sendEmailOrders = (email) => {

        console.log(selectionModel.length);

        setLoading(true);

        var order_id = props.order_id;
        var entity_id = props.entity_id;
        var kh_code = props.customer;
        var branch = props.branch;

        if(selectionModel.length === 0){
          ReactDOM.render(<Alert severity="warning" sx={{ width: '98%' }}>
              <AlertTitle>{configData.STRINGS.MESSAGE.WARNING_TITLE}</AlertTitle>
              {configData.STRINGS.MESSAGE.NO_SELECTED_PRODUCTS}
            </Alert>, document.getElementById(order_id));
        }
        else{
          var text = '<b>Automatically generated order email from Inter Cars Romania!</b><br><br>';
          var textInternal = '<b>Automatic order from Inter Cars Romania!</b><br><br>';

        text += '<table border="1" style="border-collapse: collapse;"><tbody>';

        
        for (let i = 0; i < selectionModel.length; i++) {
            text += '<tr><td style="padding: 5px;">'+trimBrand(selectionModel[i].name) + '</td><td style="padding: 5px;">' + selectionModel[i].qty_ordered + '</td></tr>';
            textInternal += trimBrand(selectionModel[i].name) + " - " + selectionModel[i].qty_ordered + " бр. - "+ selectionModel[i].price + "<br>";
          }

          text += '</tbody></table>';

          text += "<br><b>Please, send us feedback in case of unavailability of the product/s!</b><br>";
          text += "<br>In case of questions, you can contact us on email (BGHQOEEmployees@intercars.eu)<br>";
          textInternal += "Customer: "+kh_code+' / '+branch;
          text += "<br><b>This is an automatically generated email! Please, do not answer!</b><br>";
          textInternal += "<br><b>This is an automatically generated email! Please, do not answer!</b><br>";

        const data = {email:{receiver:email, subject: 'Order - '+order_id, message: text}}

        const dataInternal = {email:{receiver:configData.SUPLIERS_EMAILS.INTERNAL_EMAIL, subject: 'Order - '+order_id, message: textInternal}}

        var todayDateComment = new Date().toJSON().slice(0,10).replace(/-/g,'/');

        var todayDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');

        const dataComment = {"order_id":order_id, "entity_id": entity_id, "statusHistory": {"comment": "Sent to supplier "+email+" via email on date:"+todayDateComment, "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};
        
        //console.log(entity_id);

        const externalEmail = axios.post(configData.EXTERNAL_APIS.EMAIL_SERVICE_SEND, data);
        const internalEmail = axios.post(configData.EXTERNAL_APIS.EMAIL_SERVICE_SEND, dataInternal);
        const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES_POST, dataComment);

        axios.all([externalEmail, internalEmail, postComment])
        .then(ReactDOM.render(<LinearProgress />, document.getElementById(order_id)))
        .then(axios.spread((...responses) => {
        console.log("RESPONSE RECEIVED: ", responses[0]);
        console.log("RESPONSE RECEIVED: ", responses[1]);
        console.log("RESPONSE RECEIVED: ", responses[2]);
        ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        External: {responses[0].data}<br></br>Internal: {responses[1].data}<br></br>Information saved: {responses[1].status}
        </Alert>, document.getElementById(order_id));
        reqData();
        }))
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById(order_id));
        })
    }
    }

    // Orders via Euroest.ro API
    const sendEuroestOrder = () => {

        //const alertBar = document.getElementById(order_id);

        let token = document.head.querySelector('meta[name="csrf-token"]');

        if (token) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
        } else {
            console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
        }

        var order_id = props.order_id;
        var entity_id = props.entity_id;

        const products = [];

        for (let i = 0; i < selectionModel.length; i++) {
            var productNumber = trimBrand(selectionModel[i].name);
            var productManufacturer = selectionModel[i].name.split('_');
            var productQty = selectionModel[i].qty_ordered;

            products.push({"itemcode":productNumber, "quantity":productQty, "brand": productManufacturer[0]})
          }

        var orderBody = {
            "order":products,
            "remarks": "Order via API",
            "customercode": order_id
        };

        console.log(orderBody);

        if(selectionModel.length === 0){
            ReactDOM.render(<Alert severity="warning" sx={{ width: '98%' }}>
                <AlertTitle>Warning</AlertTitle>
                Please, select some products from the list!
              </Alert>, document.getElementById(order_id))
        }
        else{
        axios.post(configData.EXTERNAL_APIS.EUROEST_PLACE_ORDER, orderBody)
        .then(ReactDOM.render(<LinearProgress />, document.getElementById(order_id)))
        .then((res) => {
          var todayDateComment = new Date().toJSON().slice(0,10).replace(/-/g,'/');

          var orderedItems = res.data.orderlines;

          var itemsOrdered = ""

          for( let i = 0; i < orderedItems.length; i++) {
            itemsOrdered += orderedItems[i].itemcode
            itemsOrdered += orderedItems[i].quantity
            itemsOrdered += " / "
          }

          const dataComment = { "entity_id": entity_id, "statusHistory": {"comment": "Поръчката е изпратена към Euroest. Поръчка номер:"+res.data.orderno+" | Пръчяни артикули:"+itemsOrdered, "status": "processing", "created_at": todayDateComment,"entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};
        
          axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

          console.log("RESPONSE RECEIVED: ", res);

          ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>

        Поръчка номер: {res.data.orderno} Поръчани артикули: {itemsOrdered}
        </Alert>, document.getElementById(order_id));

        })
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById(order_id));
        })
    }}

    var order_id = props.order_id;

    var messagesBody = { "order_id": order_id };

    //define asyc get request to Magento API
    const reqData = async () => {
      const responseData = await axios.get(configData.MAGENTO_APIS.MAGENTO_ORDER_COMMENTS+order_id)
    //return response with data

    setComments(responseData.data.items);
    //setLoading(false);
    //return responseData.data;
    const responseDataMsg = await axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES, messagesBody)
    //return response with data
    setMessages(responseDataMsg.data.items);

    setLoading(false);

    }

    if(isLoading) {
      reqData();
    }
    else{
      //var sorting = comments.items;
      
      var row = comments;
      var rowMessages = messages;

      //console.log(row.items)

  return (
    <>
    <div id={order_id}></div>
    <div id="grid">
    <Stack direction="row" spacing={1} paddingBottom={2}>
        <Button size="small" variant="outlined" disabled="true" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_BMW_1)} endIcon={<SendIcon />}>BMW order</Button>
        <Button size="small" variant="outlined" disabled="true" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_BMW_2)} endIcon={<SendIcon />}>BMW order AB</Button>
        <Button size="small" variant="outlined" disabled="true" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_MERCEDES_1)} endIcon={<SendIcon />}>Mercedes order</Button>
        <Button size="small" variant="outlined" disabled="true" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_PORSCHE_1)} endIcon={<SendIcon />}>Porsche order</Button>
        <Button size="small" variant="outlined" disabled="true" onClick={sendEuroestOrder} endIcon={<SendIcon />}>Euroest order</Button>
        <Button size="small" variant="outlined" disabled="true" onClick={sendEuroestOrder} endIcon={<SendIcon />}>Ronax order</Button>
    </Stack>
    <Stack sx={{ width: '100%' }} spacing={2} paddingBottom={2}>
     {rowMessages.map((option) => (
                  <div key={option.id}>
                    <Alert severity="warning">{option.message}</Alert>
                  </div>
                ))}
    </Stack>
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
                getRowId={(data) => data.product_id}
                rows={data} 
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = data.filter((row) =>
                      selectedIDs.has(row.product_id)
                    );
                    setSelectionModel(selectedRowData)
                 
                  }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{
                  boxShadow: 0,
                  border: 2,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                }}
              />
              </Box>
    </div>
    <div>
    <Collapse in={true} timeout="auto" unmountOnExit>
          <Box sx={{ m: 1, width: 'auto' }} spacing={2}>
          <Stack direction="row" spacing={2}>
              <TextField
                id="outlined-multiline-flexible"
                label="Adauga un comentariu"
                multiline
                maxRows={4}
                value={valueComment}
                onChange={handleChangeComment}
                sx={{ m: 1, width: '70ch' }}
                size="small"
              />

              <TextField
                id="outlined-select-currency"
                select
                size="small"
                label="Select"
                value={currentStatus}
                onChange={handleChangeStatus}
                style={{ mt: 1 }}
              >
                {statusOption.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <Button size="small" variant="outlined" onClick={() => publishComment()} href="#outlined-buttons">
              Adăuga
            </Button>
            </Stack>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{configData.STRINGS.ORDER_HISTORY.COLUMNS.PRODUCT_COMMENT}</TableCell>
                    <TableCell>{configData.STRINGS.ORDER_HISTORY.COLUMNS.PRODUCT_COMMENT_DATE}</TableCell>
                    <TableCell>{configData.STRINGS.ORDER_HISTORY.COLUMNS.PRODUCT_COMMENT_FRONT}</TableCell>
                    <TableCell>{configData.STRINGS.ORDER_HISTORY.COLUMNS.PRODUCT_COMMENT_NOTIFIED}</TableCell>
                    <TableCell align="right">{configData.STRINGS.ORDER_HISTORY.COLUMNS.PRODUCT_COMMENT_ORDER_STATUS}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.map((statusRow) => (
                    <TableRow key={statusRow.entity_id}>
                      <TableCell component="th" scope="row">
                      <Box sx={{ fontWeight: 'bold' }}> {statusRow.extension_attributes ? statusRow.extension_attributes.added_by+" " : "OE Department "}</Box>{statusRow.comment}
                      </TableCell>
                      <TableCell>{statusRow.created_at}</TableCell>
                      <TableCell>{statusRow.is_visible_on_front === 1 ? <CheckCircleIcon color="success"/> : <CancelIcon color="error"/>}</TableCell>
                      <TableCell>{statusRow.is_customer_notified === 1 ? <CheckCircleIcon color="success"/> : <CancelIcon color="error"/>}</TableCell>
                      <TableCell align="right">{statusRow.status}</TableCell>
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
    </div>
    </>
  );
}
}