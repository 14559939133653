import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import configData from "../config/config.json";

export default function OrdersTable(props) {

  var row = props.rows;

    return (
        <TableRow key={row.increment_id}  sx={{ '& > *': { borderBottom: 'unset' } }} style={{ background: '#cccccc'}}>
        <TableCell>
        </TableCell>
        <TableCell align="center">{row.increment_id}</TableCell>
        <TableCell align="center">{row.created_at}</TableCell>
        <TableCell align="center">{row.status === "processing" ? <Chip label="processing" size="small" color="primary" /> : <Chip label={row.status} size="small"/>}</TableCell>
        <TableCell align="center">{row.extension_attributes.kh_code} / {row.extension_attributes.branch}</TableCell>
        <TableCell align="center">{row.grand_total} {configData.MAIN_CONFIGURATIONS.CURRENCY_SYMBOL}</TableCell>
      </TableRow>
    );
  
}